import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Info from './Pages/Info';
import ErrorBoundary from './Components/ErrorBoundary';
import './App.scss'

const App: React.FC = () => {
  return (
    <div className="wrapper">
      <Router>
            <Switch>
              <Route exact path="/" component={ErrorBoundary} />
              <Route exact path="/info" component={Info} />
            </Switch>
      </Router>
    </div>
  );
}

export default App;
