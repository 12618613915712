import React, { useState } from "react";
import {
  numberOfSelectedMouscleGroups,
  generateEverything,
  saveToLocalStorage,
  getFromLocalStorage
} from "../utils/helper";
import { IFormData } from "../utils/interfaces";
import Form from "../Components/Form";
import List from "../Components/List";

const Home: React.FC = () => {
  const handleToggleChange = (name: string | null, value: boolean) => {
    updateForm({ ...formData, ...{ [name ? name : "warmup"]: !value } });
  };
  const handleLevelChange = (value: string) => {
    updateForm({ ...formData, ...{ level: value.toString() } });
  };
  const handleGroupChange = (
    type: string,
    e: React.ChangeEvent<HTMLInputElement>,
    max: number | undefined
  ) => {
    const target = e.target;
    switch (type) {
      case "muscle_selection":
        if (
          numberOfSelectedMouscleGroups(formData.specificMuscles) >
            (max ? max - 1 : 0) &&
          target.checked
        ) {
          return;
        } else {
          updateForm({
            ...formData,
            ...{
              specificMuscles: {
                ...formData.specificMuscles,
                [target.name]: target.checked
              }
            }
          });
        }
        break;
      case "equipment":
        updateForm({
          ...formData,
          ...{
            equipment: { ...formData.equipment, [target.name]: target.checked }
          }
        });
        break;
      default:
        updateForm({ ...formData, ...{ [target.name]: target.checked } });
        break;
    }
  };
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) =>
    e.target.name === "schema"
      ? updateForm({
          ...formData,
          ...{
            showResult: false,
            exercizes: [],
            specificMuscles: {},
            schema: e.target.value
          }
        })
      : updateForm({ ...formData, [e.target.name]: e.target.value });

  const handleSubmit = () => {
    const { level, equipment, specificMuscles, schema } = formData;
    const exercizes = generateEverything(
      schema,
      level,
      equipment,
      specificMuscles
    );
    const newSettings = { ...formData, ...{ showResult: true, exercizes } };
    saveToLocalStorage("state", newSettings);
    updateForm(newSettings);
  };

  let defaultState =
    getFromLocalStorage("state") && getFromLocalStorage("state")
      ? getFromLocalStorage("state")
      : {
          level: "1",
          warmup: false,
          cooldown: false,
          showResult: false,
          exercizes: [],
          equipment: {},
          specificMuscles: {},
          schema: ""
        };

  const [formData, updateForm] = useState<IFormData>(defaultState);
  const {
    showResult,
    exercizes,
    level,
    equipment,
    warmup,
    cooldown,
    specificMuscles,
    schema
  } = formData;
  return showResult ? (
    <List
      flow={exercizes}
      schema={schema}
      level={level}
      muscles={specificMuscles}
      warmup={warmup}
      cooldown={cooldown}
      handleReset={() => localStorage.removeItem('state')}
    />
  ) : (
    <Form
      specificMuscles={specificMuscles}
      level={level}
      warmup={warmup}
      cooldown={cooldown}
      equipment={equipment}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleToggleChange={handleToggleChange}
      handleLevelChange={handleLevelChange}
      handleGroupChange={handleGroupChange}
      schema={schema}
    />
  );
};
export default Home;
