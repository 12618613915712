export const MUSCLES = [
  { name: 'legs', value: 'legs' },
  { name: 'glutes', value: 'glutes' },
  { name: 'back', value: 'back' },
  { name: 'chest', value: 'chest' },
  { name: 'shoulders', value: 'shoulders' },
  { name: 'triceps', value: 'triceps' },
  { name: 'biceps', value: 'biceps' },
  { name: 'calfs', value: 'calfs' },
  { name: 'trapesium', value: 'trapesium' },
  { name: 'forearm', value: 'forearm' },
  { name: 'core', value: 'core' },
  { name: 'abs', value: 'abs' },
];
export const LEVELS = [
  { name: 'beginner', value: '1' },
  { name: 'intermediate', value: '2' },
  { name: 'advanced', value: '3' },
];
export const TOGGLES = [{ name: 'yes', value: 'true' }, { name: 'no', value: 'false' }];
export const EQUIPMENTS = [
  { name: 'bands', value: 'bands' },
  { name: 'dumbbell', value: 'dumbbell' },
  { name: 'machine', value: 'cable_machine' },
  { name: 'kettlebell', value: 'kettlebell' },
  { name: 'bike', value: 'bike' },
  { name: 'smachine', value: 'smith_machine' },
];

export const SETS = ['curls','pullups', 'squats'];
export const ABS = 'ABS';
export const CARDIO = 'CARDIO';