import React from 'react';
import { IEquipmentProps } from '../utils/interfaces'
import { ReactComponent as CurlsIcon } from '../assets/routine/curls.svg';
import { ReactComponent as PullupsIcon } from '../assets/routine/pullups.svg';
import { ReactComponent as SquatsIcon } from '../assets/routine/squats.svg';

const Sets: React.SFC<IEquipmentProps> = ({ name }) => {
    let Icon = CurlsIcon;
    switch (name) {
        case 'curls':
            Icon = CurlsIcon;
            break;
        case 'pullups':
            Icon = PullupsIcon;
            break;
        case 'squats':
            Icon = SquatsIcon;
            break;
        default:
            Icon = CurlsIcon;
    }

    return <Icon className="mr-3" />;
}

export default Sets;