import db from "../db/exercize.json";
import schemaDB from "../db/schema.json";
import {
  Exercize,
  ObjectWithProperties,
  ISchemaData,
  ISchemaItem,
  IFlowData,
  IFormData
} from "./interfaces";
import { ABS, CARDIO } from "./constants";

export const generateRandomNumber = (max: number) =>
  Math.floor(Math.random() * max + 1);

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

export const saveToLocalStorage = (key: string, value: object): void =>
  localStorage.setItem(key, JSON.stringify(value));

export const getFromLocalStorage = (key: string): IFormData => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : false;
};

export const numberOfSelectedMouscleGroups = (
  list: ObjectWithProperties
): number => Object.values(list).filter((item: boolean) => item).length;

export const isUniqueExercize = (list: Exercize[], ex: Exercize): boolean =>
  list.filter((item: Exercize) => item.muscle === ex.muscle).length === 0;

  export const flattenList = (list: Array<IFlowData>): Exercize[] => {
    let res: Exercize[] = [];
    list.forEach((item: IFlowData) => (res = [...res, ...item.exercizes]));
    return res;
  };


export const hasEquipment = (list: string[], item: Exercize) => {
  let inList = false;
  let counter = 0;
  while (!inList && counter !== list.length - 1) {
    const equipmentName = `equipment_${list[counter]}`;
    counter++;
    if (hasKey(item, equipmentName)) {
      inList = item[equipmentName] === "1";
    }
  }

  return inList;
};

export const generateEverything = (
  schema: string,
  level: string,
  equipment: ObjectWithProperties,
  specificMuscles: ObjectWithProperties
) => {
  const muscles: string[] | string = getSelectedElements(specificMuscles);
  return generateExercizes(schema, level, equipment, muscles);
};

export const generateExercizes = (
  schema: string,
  level: string,
  equipment: ObjectWithProperties,
  muscles: string[]
): Array<IFlowData> => {
  let res: IFlowData[] = [];
  let ids: string[] = [];
  const schemaItem: ISchemaItem = schemaDB[parseInt(schema, 10)];
  schemaItem.data.forEach((item: ISchemaData) => {
    const newFlow = generateFlow(level, equipment, muscles, ids, item);
    res = [...res, newFlow.data];
    ids = [...ids, ...newFlow.ids];
  });
  return res;
};

const generateFlow = (
  level: string,
  equipment: ObjectWithProperties,
  muscles: string[],
  ids: string[],
  data: ISchemaData
) => {
  const { ex_order, rounds, reps, rest, is_flow } = data;
  let exList: Exercize[] = [];
  let newIds: string[] = ids;
  ex_order.forEach((item: string) => {
    let muscle = "";
    switch (item) {
      case "A":
        muscle = muscles[0];
        break;
      case "B":
        muscle = muscles[1];
        break;
      case "C":
        muscle = muscles[2];
        break;
      case ABS:
        muscle = "abs";
        break;
      case CARDIO:
        muscle = "cardio";
        break;
      default:
        muscle = muscles[0];
    }

    let newExercize: Exercize = getMuscleExercize(
      level,
      muscle,
      equipment,
      newIds
    );
    exList.push(newExercize);
    newIds.push(newExercize.id);
  });

  return {
    data: { rounds, reps, rest, is_flow, exercizes: exList },
    ids: newIds
  };
};

const getMuscleExercize = (
  level: string,
  muscle: string,
  equipment: ObjectWithProperties,
  ids: string[]
): Exercize => {
  const list = db.filter(
    (item: Exercize) =>
      parseInt(item.level, 10) <= parseInt(level, 10) &&
      item.muscle === muscle.toUpperCase() &&
      hasEquipment(getSelectedElements(equipment), item) &&
      ids.indexOf(item.id) === -1
  );
  return list[generateRandomNumber(list.length) - 1];
};

export const getSelectedElements = (list: ObjectWithProperties): string[] => {
  const selectedValues = Object.keys(list);
  let selected: string[] = [];
  selectedValues.forEach((item: string) => {
    if (list[item]) {
      selected.push(item);
    }
  });
  return selected;
};

export const createWarmupExercizes = (list: Array<IFlowData>): Exercize[] => {
  let warmupEx: Exercize[] = [];
  const formatList: Exercize[] = flattenList(list);
  formatList.forEach((item: Exercize) => {
    if (isUniqueExercize(warmupEx, item)) {
      warmupEx.push(item);
    }
  });

  return warmupEx;
};



