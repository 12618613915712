import React from 'react';
import { IToggle } from '../utils/interfaces';

const Toggle: React.SFC<IToggle> = ({ name, state, handleChange }) => {
    return <div
        className={`toggle btn mr-1 btn-light ${state ? 'btn-warning' : 'btn-light off'}`}
        >
        <input
            className="form-check-input"
            type="checkbox"
            data-toggle="toggle"
            data-style="mr-1"
            defaultChecked
            id={name}
        />
        <div className="toggle-group" >
            <label className="btn btn-warning toggle-on" data-value="true" data-name={name} onClick={e => handleChange(e.currentTarget.getAttribute('data-name'), e.currentTarget.getAttribute('data-value') === 'true')}>Yes</label>
            <label className="btn btn-light toggle-off" data-value="false" data-name={name} onClick={e => handleChange(e.currentTarget.getAttribute('data-name'), e.currentTarget.getAttribute('data-value') === 'true')}>No</label>
            <span className="toggle-handle btn btn-light"></span>
        </div>
    </div>;
}

export default Toggle;