
import React from 'react';
import Home from '../Pages/Home';
export default class ErrorBoundary extends React.Component {
    state = {
        error: false
    };

    componentDidCatch(error: Error, info: React.ErrorInfo) {
        this.setState({
            error
        });
    }

    render() {
        return this.state.error ? <div className="alert alert-danger" role="alert">
            An error occurred. Please refresh the page and try again.
    </div> : <Home />;
    }
}