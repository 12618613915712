import React from "react";
import schemaDB from "../db/schema.json";
import Equipment from "./Equipment";
import { numberOfSelectedMouscleGroups } from "../utils/helper";
import { IOption, IFormProps, ISchemaItem } from "../utils/interfaces";
import { MUSCLES, EQUIPMENTS } from "../utils/constants";
import logo from "./../assets/logo.png";
import { Link } from "react-router-dom";
import Toggle from './Toggle';
import Level from './Level';

const schemaValues = schemaDB.map((item: ISchemaItem, key: number) => ({
  name: item.name,
  value: `${key}`
}));

const ExercizeForm: React.SFC<IFormProps> = ({
  level,
  warmup,
  cooldown,
  handleChange,
  handleToggleChange,
  handleLevelChange,
  handleGroupChange,
  specificMuscles,
  equipment,
  handleSubmit,
  schema
}) => {
  const disableSpecificMuscles = !schema;
  const currentSchema: ISchemaItem = schemaDB[parseInt(schema, 10)];
  const enableSubmit =
    schema &&
    level &&
    numberOfSelectedMouscleGroups(equipment) >= 1 &&
    numberOfSelectedMouscleGroups(specificMuscles) >=
    parseInt(currentSchema.no_of_muscles);

  return (
    <form>
      <header className="header">
        <a className="brand mb-3" href="/">
          <img src={logo} className="brand__logo" alt="xrsz" />
        </a>
        <Link to="/info" className="header__info">
          <Equipment name="info" />
        </Link>
      </header>
      <div className="form-group row">
        <label
          htmlFor="schema"
          className="col-sm-3 col-form-label text-capitalize hide-label"
        >
          schema
        </label>
        <div
          className="btn-group col-sm-9 flex-wrap d-flex flex-row"
          data-toggle="buttons"
          id="schemaGroup"
        >
          <select
            className="custom-select"
            onChange={handleChange}
            name="schema"
            id="schema"
            value={schema}
          >
            <option value="">select a schema</option>
            {schemaValues.map((item: IOption) => (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="form-group row">
        <label
          htmlFor="warmupGroup"
          className="col-sm-3 col-form-label text-capitalize"
        >
        </label>
        <div
          className="btn-group col-sm-9 flex-wrap d-flex flex-row justify-content-between"
        >
          <div className="option-item d-flex flex-column col-sm-4">
            <span className="option-item__name">Warmup</span>
            <Toggle name="warmup" state={warmup} handleChange={handleToggleChange} />
          </div>
          <div className="option-item d-flex flex-column col-sm-4">
            <span className="option-item__name">Intensity</span>
            <Level state={level} handleChange={handleLevelChange} isSmall={false} />
          </div>
          <div className="option-item d-flex flex-column col-sm-4">
            <span className="option-item__name">Cooldown</span>
            <Toggle name="cooldown" state={cooldown} handleChange={handleToggleChange} />
          </div>
        </div>
      </div>
      <div className="form-group row">
        <label
          htmlFor="muscleGroup"
          className="col-sm-3 col-form-label text-capitalize"
        >
          select {currentSchema ? `(${currentSchema.no_of_muscles})` : ""} muscle group
        </label>
        <div
          className="btn-group col-sm-9 flex-wrap d-flex flex-row"
          data-toggle="buttons"
          id="muscleGroup"
        >
          {MUSCLES.map((item: IOption, key: number) => (
            <label
              key={key * 3}
              className={`btn btn-grid btn-secondary ${key % 3 === 1 ? 'btn-grid--with-margin' : '' }  ${
                disableSpecificMuscles ? "disabled" : ""
                } ${specificMuscles[item.value] ? "active btn-warning" : ""}`}
            >
              <input
                type="checkbox"
                name={item.value}
                className="d-none"
                checked={specificMuscles[item.value]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!handleGroupChange) return;
                  handleGroupChange(
                    "muscle_selection",
                    event,
                    parseInt(schemaDB[parseInt(schema)].no_of_muscles)
                  );
                }}
                disabled={disableSpecificMuscles}
              />
              {item.name}
            </label>
          ))}
        </div>
      </div>
      <div className="form-group row">
        <label
          htmlFor="equipmentGroup"
          className="col-sm-3 col-form-label text-capitalize"
        >
          select available equipment
        </label>
        <div
          className="btn-group col-sm-9 flex-wrap d-flex flex-row"
          data-toggle="buttons"
          id="equipmentGroup"
        >
          {EQUIPMENTS.map((item: IOption, key: number) => (
            <label
              key={key * 3}
              className={`btn btn-grid btn-grid--with-icon btn-secondary ${key !== 0  ? 'btn-grid--with-margin' : '' } ${
                equipment[item.value] ? "active btn-warning" : ""
                }`}
            >
              <input
                type="checkbox"
                name={item.value}
                className="d-none"
                checked={equipment[item.value]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (!handleGroupChange) return;
                  handleGroupChange("equipment", event);
                }}
              />
              <Equipment name={item.name} />
            </label>
          ))}
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-lg btn-warning btn-block btn-submit"
        onClick={handleSubmit}
        disabled={!enableSubmit}
      >
        get trainig schema
      </button>
    </form>
  );
};

export default ExercizeForm;
