import React from "react";
import { Exercize, IRoundProps, IFlowData } from "../utils/interfaces";
import Sets from "./Sets";
import { SETS } from "../utils/constants";

const Round: React.SFC<IRoundProps> = ({ flow, isWarmup }) => {
  return (
    <>
      {flow.map((item: IFlowData, key: number) => (
        <>
          <div
            className={`alert alert--small round alert-${
              isWarmup ? "secondary" : "warning"
            } `}
            role="alert"
          >
            {isWarmup
              ? "warmup round"
              : item.is_flow === "1"
              ? `round ${++key} - circuit training`
              : `round ${++key} - single exercizes`}
            {item.is_flow === "1" && (
              <span className="badge badge-light">x{item.rounds}</span>
            )}
          </div>
          <ul className={`list-unstyled ${item.is_flow === "1" ? 'list-unstyled--with-margin' : ''}`}>
            {item.exercizes.map((ex: Exercize, index: number) => (
              <li className={`media media-with-separator ${item.is_flow === "1" && index === item.exercizes.length - 1 ? 'media-with-separator--no-dash' : ''}`} key={index}>
                <Sets name={SETS[isWarmup ? 3 : key]} />
                <div className="media-body">
                  <h5 className="mt-0 mb-1 list__title">
                    {ex.name}
                    <span
                      className={`badge badge-${
                        isWarmup ? "secondary" : "warning"
                      } list__badge`}
                    >{`${
                      isWarmup ? "1" : item.is_flow === "1" ? "" : item.rounds
                    }x${item.reps || 15}`}</span>
                  </h5>
                  {ex.description}
                </div>
                {item.is_flow === "0" && (
                  <small className="rest">
                    rest between sets: {item.rest}s
                  </small>
                )}
                {item.is_flow === "1" &&
                  index === item.exercizes.length - 1 && (
                    <div className="alert alert--small round alert-secondary alert--bottom">{`active rest for circuite: ${item.rest}s`}</div>
                  )}
              </li>
            ))}
          </ul>
        </>
      ))}
    </>
  );
};

export default Round;
