import React from "react";
import { IMatchProps } from "../utils/interfaces";
import { Link } from "react-router-dom";

const Item: React.SFC<IMatchProps> = ({ match }) => {
  return (
    <div className="card">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro rerum
      nesciunt quae error! Necessitatibus doloribus odit suscipit debitis! Ut
      praesentium fuga nesciunt voluptate est harum a iusto facere similique
      iure.
      <Link to="/">back home</Link>
    </div>
  );
};

export default Item;
