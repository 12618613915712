import React from 'react';
import { ILevel } from '../utils/interfaces';

const Level: React.SFC<ILevel> = ({ state, isSmall, handleChange }) => {
    return <div 
    className={`signal-bars mt1 sizing-box ok ${state === '3' ? 'three' : (state === '2' ? 'two' : 'one')}-bars ${isSmall ? 'signal-bars--small' : ''}`} 
    onClick={() => handleChange(state === '1' || state === '2' ? String(parseInt(state, 10) + 1) : '1')}>
        <div className="first-bar bar"></div>
        <div className="second-bar bar"></div>
        <div className="third-bar bar"></div>
    </div>
}

export default Level;