import React from 'react';
import {IEquipmentProps} from '../utils/interfaces'
import {ReactComponent as WeightIcon} from '../assets/svg/weight.svg';
import {ReactComponent as BandsIcon} from '../assets/svg/bands.svg';
import {ReactComponent as KettlebellIcon} from '../assets/svg/kettlebell.svg';
import {ReactComponent as BikeIcon} from '../assets/svg/bike.svg';
import {ReactComponent as SMachineIcon} from '../assets/svg/smachine.svg';
import {ReactComponent as MachineIcon} from '../assets/svg/machine.svg';
import {ReactComponent as BagIcon} from '../assets/svg/bag.svg';
import {ReactComponent as InfoIcon} from '../assets/svg/info.svg';

const Equipment: React.SFC<IEquipmentProps> = ({name}) =>{
    let Icon = WeightIcon;
    switch(name) {
        case 'dumbbell':
            Icon = WeightIcon;
            break;
        case 'bands':
            Icon = BandsIcon;
            break;
        case 'kettlebell':
            Icon = KettlebellIcon;
            break;
        case 'bike':
            Icon = BikeIcon;
            break;
        case 'smachine':
            Icon = SMachineIcon;
            break;
        case 'machine':
            Icon = MachineIcon;
            break;
        case 'bag':
            Icon = BagIcon;
            break;
        case 'info':
            Icon = InfoIcon;
            break;
        default:
            Icon = WeightIcon;
    }
    return <Icon className="icon"/>;
}

export default Equipment;