import React from "react";
import { IListProps } from "../utils/interfaces";
import schemaDb from "../db/schema.json";
import { getSelectedElements, createWarmupExercizes } from "../utils/helper";
import Round from "./Round";
import Level from './Level';

const List: React.SFC<IListProps> = ({
  flow,
  schema,
  level,
  muscles,
  warmup,
  cooldown,
  handleReset
}) => {
  return (
    <>
      <div className="text-center">
        <h1 className="h3 mb-3 font-weight-normal">Today's workout</h1>
        <div>
          {`${schemaDb[parseInt(schema)].name}`}
          <Level state={level} handleChange={() => { }} isSmall />
        </div>
        {/* <p>{`${warmup ? "warmup" : ""} | ${getSelectedElements(muscles).join(" - ")}  ${cooldown ? "| cooldown" : ""}`}</p> */}
        <div className="d-flex justify-content-between selected-schema">
          <span>warmup <em className="selected-schema__option">{warmup ? 'yes' : 'no'}</em></span>
          <span>muscle groups <em className="selected-schema__option">{getSelectedElements(muscles).join(" - ")}</em></span>
          <span>cooldown <em className="selected-schema__option">{cooldown ? 'yes' : 'no'}</em></span>
        </div>
      </div>
      {warmup && (
        <Round
          flow={[
            {
              rounds: "",
              reps: "",
              rest: "",
              is_flow: "",
              exercizes: createWarmupExercizes(flow)
            }
          ]}
          isWarmup
        />
      )}
      <Round flow={flow} isWarmup={false} />
      <a href="/" onClick={handleReset}>generate new list</a>
    </>
  );
};

export default List;
